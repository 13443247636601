<template>
  <div class="foot">
    <div class="foot-left foot-text">
      <a href="https://fb.com/laravieira.me" title="Facebook" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.79 59.3"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M20,59.3v-27h9.08l1.36-10.54H20V15c0-3.05.85-5.13,5.22-5.13h5.58V.42A73.51,73.51,0,0,0,22.66,0C14.61,0,9.1,4.91,9.1,13.94v7.77H0V32.25H9.1V59.3Z"/></g></g></svg>
      </a>
      <a href="https://twitter.com/laravieirame" title="Twitter" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.02 42.28"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M16.36,42.28C36,42.28,46.72,26,46.72,11.91q0-.69,0-1.38A21.4,21.4,0,0,0,52,5a21.33,21.33,0,0,1-6.13,1.69A10.78,10.78,0,0,0,50.58.78a21.42,21.42,0,0,1-6.77,2.59,10.68,10.68,0,0,0-18.47,7.3,10.23,10.23,0,0,0,.28,2.43A30.27,30.27,0,0,1,3.62,2,10.7,10.7,0,0,0,6.92,16.2a10.54,10.54,0,0,1-4.83-1.33V15a10.69,10.69,0,0,0,8.56,10.47,10.88,10.88,0,0,1-2.81.37,11,11,0,0,1-2-.19,10.69,10.69,0,0,0,10,7.41A21.42,21.42,0,0,1,2.54,37.63,21.26,21.26,0,0,1,0,37.48a30.18,30.18,0,0,0,16.36,4.8"/></g></g></svg>
      </a>
      <a href="https://www.instagram.com/laravieira.me" title="Instagram" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.99 48.99"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M48.84,14.39A17.9,17.9,0,0,0,47.7,8.45a12.06,12.06,0,0,0-2.82-4.34,12,12,0,0,0-4.34-2.82A17.91,17.91,0,0,0,34.59.15C32,0,31.15,0,24.49,0S17,0,14.4.15a17.91,17.91,0,0,0-6,1.14A11.93,11.93,0,0,0,4.11,4.11,11.93,11.93,0,0,0,1.29,8.45,17.9,17.9,0,0,0,.15,14.39C0,17,0,17.84,0,24.49S0,32,.15,34.59a17.91,17.91,0,0,0,1.14,5.95,12,12,0,0,0,2.82,4.34A12.06,12.06,0,0,0,8.45,47.7a17.91,17.91,0,0,0,6,1.14C17,49,17.84,49,24.49,49s7.49,0,10.1-.15a17.91,17.91,0,0,0,5.95-1.14,12.46,12.46,0,0,0,7.16-7.16,17.91,17.91,0,0,0,1.14-5.95C49,32,49,31.14,49,24.49S49,17,48.84,14.39Zm-4.41,20a13.73,13.73,0,0,1-.84,4.55,8.22,8.22,0,0,1-4.65,4.65,13.73,13.73,0,0,1-4.55.84c-2.58.12-3.36.14-9.9.14s-7.31,0-9.89-.14a13.73,13.73,0,0,1-4.55-.84,7.71,7.71,0,0,1-2.82-1.84A7.56,7.56,0,0,1,5.4,38.94a13.47,13.47,0,0,1-.84-4.55c-.12-2.58-.15-3.36-.15-9.9s0-7.31.15-9.89a13.47,13.47,0,0,1,.84-4.55A7.6,7.6,0,0,1,7.23,7.23,7.56,7.56,0,0,1,10.05,5.4a13.22,13.22,0,0,1,4.55-.84c2.58-.12,3.35-.15,9.89-.15s7.32,0,9.9.15a13.22,13.22,0,0,1,4.55.84,7.44,7.44,0,0,1,2.81,1.83,7.62,7.62,0,0,1,1.84,2.82,13.73,13.73,0,0,1,.84,4.55c.12,2.58.14,3.35.14,9.89S44.55,31.81,44.43,34.39Z"/><path d="M24.49,11.92A12.58,12.58,0,1,0,37.07,24.49,12.57,12.57,0,0,0,24.49,11.92Zm0,20.74a8.17,8.17,0,1,1,8.17-8.17A8.17,8.17,0,0,1,24.49,32.66Z"/><path d="M37.57,8.48a2.94,2.94,0,1,0,2.94,2.94A2.94,2.94,0,0,0,37.57,8.48Z"/></g></g></svg>
      </a>
      <a href="https://www.behance.net/laravieirame" title="Behance" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.46 35.29"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M0,0C5.25,0,10.51,0,15.76.07a19,19,0,0,1,5.09.72C24.17,1.77,26.48,4,27,7.5A8.12,8.12,0,0,1,23.21,16a1.85,1.85,0,0,0-.27.31,8.9,8.9,0,0,1,5.26,8.12c.23,5.14-3.21,9.21-8.72,10.35a25.47,25.47,0,0,1-4.78.46c-4.9.06-9.8,0-14.7,0ZM7.11,14.12c2.2,0,4.31,0,6.41,0a19.69,19.69,0,0,0,3.27-.17c2-.37,3.37-1.78,3.38-3.36a3.42,3.42,0,0,0-3.43-3.25c-3.18-.21-6.38,0-9.63,0Zm0,14.09c3,0,6,0,8.89,0a5.42,5.42,0,0,0,1.7-.41,3.26,3.26,0,0,0,2.45-3,3.4,3.4,0,0,0-2.32-3.09,3.26,3.26,0,0,0-1.23-.35c-3.15,0-6.3,0-9.49,0Zm49.36-4.68H37.79c-.63,3.42,2.68,6.85,6.4,6.45A16.87,16.87,0,0,0,48,28.61a3,3,0,0,1,.93-.36c2.24,0,4.47,0,7,0a20.9,20.9,0,0,1-1.15,2.1C52.32,33.63,48.89,35,45,35.22a18.94,18.94,0,0,1-5.46-.52c-5-1.26-8.16-5.15-8.67-10.61a17.48,17.48,0,0,1,1.47-9.64c2.32-4.6,7.4-7.09,13-6.36,6.45.85,9.93,4.26,11,10.79a2.5,2.5,0,0,0,.19.41Zm-7.11-4.75c-.34-3.61-2.55-5.34-6.43-5.13a5,5,0,0,0-5,5.13ZM51.69.08H35.38V4.65H51.69Z"/></g></g></svg>
      </a>
      <a href="https://www.linkedin.com/in/laravieirame/" title="LinkedIn" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.51 48.43"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M.8,16.08H10.86V48.43H.8ZM5.83,0A5.83,5.83,0,1,1,0,5.83,5.83,5.83,0,0,1,5.83,0M17.17,48.43h10v-16c0-4.22.8-8.31,6-8.31S38.47,29,38.47,32.7V48.43h10V30.69c0-8.71-1.88-15.41-12-15.41A10.56,10.56,0,0,0,27,20.5h-.14V16.08H17.17Z"/></g></g></svg>
      </a>
      <a href="https://br.pinterest.com/laravieirame" title="Pinterest" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.38 57.15"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M24,0c12.6,0,22.39,9,22.39,21,0,12.52-7.89,22.59-18.84,22.59-3.68,0-7.14-1.92-8.33-4.17,0,0-1.82,6.93-2.26,8.63a36.54,36.54,0,0,1-3.74,8.24A1.81,1.81,0,0,1,11,57a1.78,1.78,0,0,1-1.2-1.53,32.32,32.32,0,0,1,.24-9.27c.66-2.83,4.26-18,4.26-18a13,13,0,0,1-1.09-5.39c0-5.05,2.93-8.82,6.57-8.82,3.1,0,4.6,2.33,4.6,5.12,0,3.11-2,7.77-3,12.09a5.27,5.27,0,0,0,5.38,6.56c6.45,0,11.41-6.8,11.41-16.63C38.2,12.43,32,6.35,23,6.35c-10.33,0-16.4,7.75-16.4,15.76a14.14,14.14,0,0,0,2.7,8.29,1.06,1.06,0,0,1,.25,1c-.27,1.14-.88,3.61-1,4.12s-.53.8-1.22.48C2.84,33.93,0,27.3,0,22,0,10.52,8.32,0,24,0"/></g></g></svg>
      </a>
      <a href="https://www.youtube.com/channel/UCNHxrnUWnuMMO0kNIyoc9wQ" title="YouTube" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.48 39.02"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M54.92,8.42s-.54-3.83-2.2-5.51A8,8,0,0,0,47.16.56C39.39,0,27.75,0,27.75,0h0S16.08,0,8.32.56A7.93,7.93,0,0,0,2.76,2.91C1.1,4.59.56,8.42.56,8.42a83.1,83.1,0,0,0-.56,9V21.6a83.16,83.16,0,0,0,.56,9s.54,3.82,2.2,5.51c2.11,2.21,4.88,2.14,6.12,2.37,4.44.43,18.86.56,18.86.56s11.65,0,19.42-.58a8,8,0,0,0,5.56-2.35c1.66-1.69,2.2-5.51,2.2-5.51a83.16,83.16,0,0,0,.56-9V17.39A83.1,83.1,0,0,0,54.92,8.42ZM22,26.7V11.12l15,7.82Z"/></g></g></svg>
      </a>
      <a href="https://www.tiktok.com/@laravieira.me" title="TikTok" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.72 51.98"><g id="Layer_2" data-name="Layer 2"><g id="Objects"><path d="M25.24,2h8.45c.82,7.22,5,11,12,12v8.3c-4.28.19-8.06-1.23-11.77-3.6-.05.59-.13,1-.13,1.45,0,5.28,0,10.56,0,15.83,0,8.9-6.42,15.4-15.66,16-7.38.48-14.78-6-15.95-13.87A15.82,15.82,0,0,1,18.33,20.27c.52,0,1,.13,1.63.21v8.4c-.45,0-.9,0-1.34,0A7.18,7.18,0,1,0,17.5,43.2,7.14,7.14,0,0,0,25.22,36c.06-10.55,0-21.1,0-31.65Z"/><path d="M23.2,0h8.44c.82,7.22,5,11,12,12v8.3c-4.29.19-8.07-1.23-11.78-3.6,0,.59-.12,1-.12,1.46,0,5.27,0,10.55,0,15.82-.05,8.9-6.42,15.4-15.66,16-7.39.48-14.79-6-15.95-13.87A15.82,15.82,0,0,1,16.29,18.31c.51,0,1,.13,1.62.21v8.41c-.45,0-.89,0-1.34,0a7.18,7.18,0,1,0-1.12,14.32A7.13,7.13,0,0,0,23.17,34c.06-10.55,0-21.1,0-31.66Z"/><path d="M36.49,9.31A12,12,0,0,1,32,2H25.24V4.32c0,10.55,0,21.1,0,31.65A7.14,7.14,0,0,1,17.5,43.2a7,7,0,0,1-5.46-3,7.29,7.29,0,0,1-3.37-6.38,7.21,7.21,0,0,1,7.9-6.87c.45,0,.89,0,1.34,0V20.26A15.84,15.84,0,0,0,2.2,38.09a16.5,16.5,0,0,0,4,8.49A14.39,14.39,0,0,0,16.11,50c9.24-.61,15.61-7.11,15.66-16,0-5.27,0-10.55,0-15.82,0-.44.07-.87.12-1.46,3.71,2.37,7.49,3.79,11.78,3.6V12A15.53,15.53,0,0,1,36.49,9.31Z"/></g></g></svg>
      </a>
    </div>
    <div class="foot-right foot-text">
      <p>2015 - {{new Date().getFullYear()}} | &copy; <a href='https://laravieira.me'>Lara Vieira</a> with. Icons from <a href='https://www.freepik.com/vectors/banner'>Vectorium.</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foot",
}
</script>

<style scoped>
.foot {
  position: fixed;
  width: 100vw;
  bottom: 0px;
}
.foot-text {
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: lighter;
  font-family: 'Montserrat', sans-serif;
}
.foot-text a, foot-text a:visited {
  color: #D06BD6;
  font-weight: normal;
  text-decoration: none;
  transition: color 100ms;
}
.foot-right a:hover, .foot-right a:focus {
  color: #C600CC;
  border-bottom: 1px solid #600ABF;
}
.foot-left a:hover svg, .foot-left a:focus svg {
  fill: #600ABF;
}
.foot-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin: 10px;
}
.foot-left svg {
  text-decoration: none;
  height: 1rem;
  margin: 0px 0.5rem;
  fill: #B52AD6;
  transition: fill 100ms;
}
.foot-right {
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 10px;
}

@media (max-width: 650px) {
  .foot-left, .foot-right {
    position: relative;
    display: block;
    width: max-content;
    margin: 20px auto;
    text-align: center;
  }
}
</style>